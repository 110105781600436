
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.dashboard {
  background: $color-main;
  min-height: 100vh;
  max-width: 100px;
  width: 100%;
  @include notebook {
    flex: none;
  }

  @include tablet {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid;
    min-height: 0;
    max-width: 100%;
    flex: auto;
  }
}

.logout {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include tablet {
    min-height: 35px;
    margin: 0 10px;
  }
}

.dashboard__wrap {
  position: fixed;
  left: 10px;

  @include tablet {
    position: relative;
    display: flex;
  }
}
