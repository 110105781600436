
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.info {
  font-size: $size-font-small;
  position: relative;
  flex: 1;

  &__icon {
    display: inline-block;
    border: 1px solid $color-dark;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    text-align: center;
    line-height: 1;
    cursor: pointer;

    &:hover > div {
      display: inline-block;
    }
  }

  &__content {
    text-align: left;
    display: none;
    font-size: $size-font-tiny;
    position: absolute;
    top: 15px;
    left: 0;
    background: $color-light;
    padding: 10px;
    box-shadow: 0px 0px 18px #c7c7c7;
    max-width: 250px;
    z-index: 9999;

    p {
      font-size: $size-font-tiny;
    }
  }
}
