
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.wrap {
  margin-bottom: 10px;

  &__label {
    font-size: $size-font-small;
    padding-bottom: 5px;
    display: inline-block;
  }
  &__input {
    width: 100%;
    outline: none;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid $color-dark;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &__error {
    color: red;
    font-size: $size-font-small;
  }
}
