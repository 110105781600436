
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
@import url('https://unpkg.com/leaflet-geosearch@2.6.0/assets/css/leaflet.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $size-font-main;

  @include tablet {
    font-size: $size-font-small;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  display: flex;

  @include tablet {
    display: block;
  }
}

.pages {
  width: calc(100% - 100px);

  &-auth {
    width: 100%;
  }
  @include tablet {
    width: 100%;
  }
}

.modal-status__wrap {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.modal-status__close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 10;
  color: $color-light;

  @include tablet {
    right: 10px;
    top: 10px;
  }
}

.modal-status__expire {
  position: relative;
}

.iconMarker {
  width: 10px;
  height: 10px;
  border: 3px solid red;
  border-radius: 50%;
  background: #ffffff;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: $size-font-main;
  line-height: 100%;
  display: flex;
  flex-shrink: 0;
  z-index: 1;
}
.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}
