
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.users {
  &__table_wrap {
    @include tablet {
      overflow-x: auto;
    }
  }
  tbody {
    td {
      padding: 20px;
    }
  }

  &__wrap_ava {
    border-radius: 50%;
    background: $color-main;
    height: 50px;
    width: 50px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &__ava {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &__control {
    margin: 0 5px;
    cursor: pointer;
    transition: 0.3s;

    @include notebook {
      margin: 10px;
      display: block;
    }

    &--edit {
      &:hover {
        color: $color-additional;
      }
    }

    &--remove {
      &:hover {
        color: red;
      }
    }
  }
}

.empty {
  text-align: center;
  padding-top: 20px;
}
