
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.user-statistic-list {
  td {
    height: 20px!important;
  }

  &__reset {
    background: $color-additional;
    color: $color-light;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}
