
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.modal-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: show;
  animation-duration: 0.5s;
}

.modal {
  background-color: $color-light;
  border-radius: 2px;
  padding: 12px 16px;
}

.modal-close {
  position: absolute;
  color: $color-light;
  right: 8px;
  top: 4px;
  font-size: 24px;
  cursor: pointer;
}

.modal-title {
  font-size: $size-font-large;
  font-weight: $font-weight-bold;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
