
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.virtual-objects-form {
  margin: 20px 0;

  &__tagSort {
    position: relative;
    margin-bottom: 20px;
  }

  @include mobileL {
    display: block;
  }

  &__fields {
    &--input {
      border: none !important;
      border-bottom: 1px solid $color-dark !important;
      border-radius: 0 !important;
      padding: 0 !important;
      font-size: $size-font-small;
    }

    &--wrap {
      min-width: 165px;
      margin: 5px 5px 10px 5px;
    }
    &--label {
      display: block !important;
    }
  }

  &__buttons {
    button {
      margin: 0 5px;
      padding: 8px 16px;
      @include tablet {
        padding: 8px 12px;
      }

      @include mobileL {
        padding: 8px;
        margin: 0 2px;
      }
    }

    &--create {
      border: 2px solid $color-additional;
    }

    &--double {
      background: #fff;
      border: 2px solid #000;
      color: #000;
    }

    &--edit {
      background: #fff;
      border: 2px solid #000;
      color: #000;
    }

    &--remove {
      background: $color-additional-1;
      border: 2px solid $color-additional-1;
    }
  }

  &__select_wrap {
    min-width: 165px;
    margin: 5px 5px 10px 5px;
    position: relative;

    p {
      margin: 0;
      padding: 0;
      font-size: $size-font-small;
      padding-bottom: 5px;
      display: inline-block;
    }

    &--error {
      color: red;
      font-size: $size-font-small;
      position: absolute;
      bottom: -20px;
      left: 2px;
      @include mobileL {
        position: relative;
        bottom: -3px;
      }
    }
  }
}
