
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.statistic_user_game {
  &__table_wrap {
    @include tablet {
      overflow-x: auto;
    }
  }
  thead {
    td {
      cursor: pointer;
    }
  }
  tbody {
    td {
      padding: 20px;
    }
  }

  &__button_teams {
    margin: 20px;
  }

  &__view {
    cursor: pointer;
  }
}

.statistic_field.ascending:after {
  content: '▲';
  margin-left: 5px;
  color: #77898e;
  opacity: 1;
}

.statistic_field.descending:after {
  content: '▼';
  margin-left: 5px;
  color: #77898e;
  opacity: 1;
}

.statistic_field:after {
  content: '▼';
  margin-left: 5px;
  color: #77898e;
  opacity: 0.3;
}
