
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.user-form {
  &__button {
    &--cancel {
      background: $color-additional-1;
      margin-left: 10px;
    }
  }

  &--upload-box {
    text-align: center;
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
      width: 33%;
    }
  }

  &__wrap-ava {
    width: 200px;
    height: 200px;
    background: $color-main;
    border-radius: 50%;
    border: 5px solid $color-additional;
    overflow: hidden;
    transition: 0.5s;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @include tablet {
      width: 150px;
      height: 150px;
    }

    &:hover {
      filter: opacity(60%);
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    input[type='file'] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }
}
