
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.checkbox {
  display: block;
  position: relative;
  padding-left: 22px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid $color-dark;
}

.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  height: 12px;
  width: 12px;
  background-color: $color-dark;
}
