
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.scenes-create {
  padding: 20px;
  display: flex;
  justify-content: space-between;

  @include notebook {
    flex-wrap: wrap;
  }

  &__wrapper {
    flex: 1;
    margin: 0 10px;
    max-width: 50%;

    @include notebook {
      max-width: 100%;
      margin-bottom: 20px;
    }
    @include tablet {
      margin: 0 0 20px 0;
    }
  }
}
