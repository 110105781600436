
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.banners {
  width: 100%;

  &__table_wrap {
    @include tablet {
      overflow-x: auto;
    }
  }

  &__circle-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  &__control {
    margin: 0 5px;
    cursor: pointer;
    transition: 0.3s;

    @include notebook {
      margin: 10px;
      display: block;
    }

    &--edit {
      &:hover {
        color: $color-additional;
      }
    }

    &--remove {
      &:hover {
        color: red;
      }
    }
  }
}

.modal_img {
  width: 400px;
}

.empty {
  text-align: center;
  padding-top: 20px;
}
