
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.scenes {
  width: 100%;

  &__button_control {
    display: flex;
    align-items: center;
  }

  &__tagSort {
    max-width: 200px;
    width: 100%;
    margin: 10px 20px;
    position: relative;
  }

  &__table_wrap {
    @include tablet {
      overflow-x: auto;
    }
  }

  &__circle-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  &__control {
    margin: 0 5px;
    cursor: pointer;
    transition: 0.3s;

    @include notebook {
      margin: 10px;
      display: block;
    }

    &--edit {
      &:hover {
        color: $color-additional;
      }
    }

    &--remove {
      &:hover {
        color: red;
      }
    }

    &--clone {
      &:hover {
        color: #ff8800;
      }
    }
  }
}

.modal_img {
  width: 400px;
}

.empty {
  text-align: center;
  padding-top: 20px;
}
