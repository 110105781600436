
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.scenes-object {
  &__input {
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
  }

  &__teams {
    margin-top: 25px;

    &--add {
      padding: 5px 9px;
      border-radius: 4px;
      background: #9a9a9a;
    }
  }

  &__delayed-start {
    margin-top: 25px;

    &--wrap-input {
      input {
        margin: 20px 10px;
      }
    }
  }

  &__checkbox {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    &--RestartWhenARTrackingIsRestored {
      align-items: center;
      label {
        margin-bottom: 0;
        margin-right: 5px;
      }
    }
    &--error {
      color: red;
      font-size: $size-font-small;
    }
  }

  &__button {
    margin-top: 20px;
  }

  &__select_wrap {
    margin-bottom: 10px;
    &--top {
      display: flex;
      p {
        margin-right: 5px!important;
      }
    }
    p {
      margin: 0;
      padding: 0;
      font-size: $size-font-small;
      padding-bottom: 5px;
      display: inline-block;
    }
  }
}
