
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.statistic {
  &__table_wrap {
    @include tablet {
      overflow-x: auto;
    }
  }
  tbody {
    td {
      padding: 20px;
    }
  }

  &__control {
    margin: 0 5px;
    cursor: pointer;
    transition: 0.3s;

    @include notebook {
      margin: 10px;
      display: block;
    }

    &:hover {
      color: $color-additional;
    }
  }
}
