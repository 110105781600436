
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.table {
  text-align: center;
  width: 100%;
  border-collapse: collapse;

  thead {
    background: $color-main;
    td {
      padding: 10px 20px;
    }
  }

  tbody {
    td {
      padding: 10px 20px;
      border-bottom: 1px solid $color-main;
      height: 80px;
    }
    img {
      max-width: 50px;
      width: 100%;
      cursor: pointer;
    }
  }
}
