
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.pagination {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  padding: 20px;

  &__info {
    p {
      margin: 0 0 10px 0;
      padding: 0;
      line-height: 0.7;
    }

    span {
      color: $color-additional;
    }
  }

  &__pages {
    span {
      margin: 0 5px;
      cursor: pointer;
    }
  }

  &__page-size {
    p {
      margin: 0;
      padding: 0;
    }

    span {
      margin: 0 5px;
      cursor: pointer;
    }
  }

  .active {
    color: $color-additional;
  }
}

.nextArrow {
  &:before {
    content: '\276F';
    transition: 0.3s;
  }

  &:hover:before {
    color: $color-additional;
  }
}

.prevArrow {
  &:before {
    content: '\276E';
    transition: 0.3s;
  }
  &:hover:before {
    color: $color-additional;
  }
}

@media only screen and (max-width: 767px) {
  .pagination {
    padding: 20px 5px;

    &__info {
      flex: 1;
      text-align: left;
    }

    &__pages {
      text-align: center;

      span {
        margin: 0 3px;
      }
    }

    &__page-size {
      flex: 1;
      text-align: right;

      span {
        margin: 0 2px;
      }
    }
  }
}
