
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.button {
  outline: none;
  border: none;
  cursor: pointer;
  background: $color-additional;
  padding: 10px 20px;
  color: $color-light;
  border-radius: 8px;
  transition: 0.5s;

  &__disabled {
    background: #dedede !important;
    border-color: #dedede !important;
    cursor: no-drop !important;
    color: $color-light !important;
  }

  &:hover {
    opacity: 0.8;
  }
}
