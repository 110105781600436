
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.user-edit {
  padding: 20px;

  &__table {
    overflow-x: auto;
  }
}
