$color-light: #ffffff;
$color-main: #cdd7da;
$color-darker-main: #bddbde;
$color-middle: #7a7a7a;
$color-dark: #000000;
$color-additional: #8aad03;
$color-additional-1: #a81d1d;

$size-font-tiny: 10px;
$size-font-small: 12px;
$size-font-little: 13px;
$size-font-main: 14px;
$size-font-large: 22px;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;
