
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.dashboard-item {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include tablet {
    min-height: 35px;
    margin: 0 10px;
  }

  &__link {
    text-decoration: none;
    text-align: center;
    color: $color-dark;
    opacity: 0.7;
    transition: 0.5s;
  }

  .active {
    opacity: 1;
  }

  &__title {
    margin: 0;
    padding: 0;

    @include tablet {
      display: none;
    }
  }
}
