
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.team-item {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 15px;

  &__swatch {
    border-radius: 1px;
    display: inline-block;
    cursor: pointer;
  }

  &__color {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  &__popover {
    position: absolute;
    top: 20px;
    z-index: 1500;
  }

  &__cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__input-wrap {
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 200px;
    width: 100%;
  }

  &__text-disabled {
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 200px;
    width: 100%;
  }

  &__input {
    border-top: none;
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding: 2px 0;
  }

  &__button {
    padding: 5px 7px;
    border-radius: 4px;

    &--edit {
      margin-right: 5px;
      background: #f0c61e;
    }

    &--delete {
      background: #d00b0b;
    }
  }
}
