
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.tags {
  position: relative;
}

.tagsForm {
  display: flex;
  align-items: center;
  border: 1px solid #000000;
}

.tagsInput {
  border-radius: 0;
  width: 100%;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
  border: none;
}

.tagsButton {
  width: 40px;
  height: 100%;
  border: none;
  border-left: 1px solid hsl(0, 0%, 80%);
  text-align: center;
  cursor: pointer;
  color: hsl(0, 0%, 80%);
  font-weight: bold;
  padding: 3px 0;
  background: none;

  &:hover {
    color: hsl(0, 0%, 60%);
  }
}

.tagsLabel {
  margin: 0;
  padding: 0;
  font-size: 12px;
  padding-bottom: 5px;
  display: inline-block;
}

.tagItem {
  background: hsl(0, 0%, 90%);
  display: inline-block;
  flex-wrap: wrap;
  margin: 5px 5px 5px 0;
  padding: 5px 0 5px 10px;
}

.tagRemove {
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 2px;
    bottom: 0;
    left: 10px;
    right: 0;
    width: 2px;
    height: 14px;
    background: red;
    transform: rotate(45deg);
  }

  &:after {
    content: "";
    position: absolute;
    display: inline-block;
    top: 2px;
    bottom: 0;
    left: 10px;
    right: 0;
    width: 2px;
    height: 14px;
    background: red;
    transform: rotate(135deg);
  }
}

.tagsHint {
  position: absolute;
  top: 65px;
  background: #e9edef;
  width: 100%;
  padding: 5px 10px;
  z-index: 5500;
  box-sizing: border-box;
  max-height: 500px;
  overflow: scroll;
}

.tagHintItem {
  margin: 5px 0;
  cursor: default;
}

.tagSortLabel {
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
  display: inline-block;
}

.tagSortInput {
  border-radius: 0;
  width: 100%;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #000000;
}
