
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.essences-sample {
  padding: 20px;
  max-width: 400px;

  &__textarea {
    width: 100%;
    resize: none;
    outline: none;
    padding: 10px;
    box-sizing: border-box;

    &--label {
      font-size: $size-font-small;
      padding-bottom: 5px;
      display: inline-block;
    }
  }

  &__wrap_inp {
    padding-top: 10px;

    p {
      margin: 0;
      padding: 0;
      font-size: $size-font-small;
      padding-bottom: 5px;
      display: inline-block;
    }

    &--input {
      border-radius: 0;
    }
  }

  &__buttons {
    margin-top: 20px;
    &--button {
      margin: 0 5px;
    }
  }
}
