
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.circle-button {
  background: #0fc7f1;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-light;
  border-radius: 50%;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  transition: 0.5s;

  @include tablet {
    width: 40px;
    height: 40px;
  }
  &:hover {
    background: rgb(118, 216, 250);
  }
}
