
          @import "src/assets/styles/_variables.scss";
          @import "src/assets/styles/_media.scss";
        
.beaconChildren {
  max-height: 500px;
  overflow: auto;

  td {
    padding: 10px 7px !important;
    font-size: $size-font-small;
    height: auto !important;
  }

  &__checkbox {
    width: 12px;
    height: 12px;

    &:after {
      height: 8px !important;
      width: 8px !important;
    }
  }
}
